// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase:
  {
    apiKey: "AIzaSyAvC7UlvWIvdd_PMa9XOHSddbwQw8Ynlz8",
    authDomain: "cometelapp-comingsoon.firebaseapp.com",
    databaseURL: "https://cometelapp-comingsoon.firebaseio.com",
    projectId: "cometelapp-comingsoon",
    storageBucket: "cometelapp-comingsoon.appspot.com",
    messagingSenderId: "617443774449",
    appId: "1:617443774449:web:0df62097512deaf0d9fe50",
    measurementId: "G-L6WTQTEWHN"
  },
  SERVER_URL: "https://cometel.app:9443",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
